<template>
  <div class="header flex-y" @click="isCity=false" id="headers">
    <!-- <div> -->
      <!-- <div class="topImg" v-if="isNavigation">
        <img src="../../assets/icon/topImg.png">
      </div> -->
    <div class="top_nav_wrap">
      <div class="top_nav flex align-center">
        <div class="city">
          <img src="../../assets/icon/adress.png" alt="">
          <!-- <div class="icon icon_location"></div> -->
          <span class="address" id="address"></span>
          <a @click.stop="isCity=true" class="cityAdressWrap">
            [切换城市]
            <div v-show="isCity" @click.stop="isCity=true" >
              <el-cascader :options="options" :show-all-levels="false" class="cityAdress" @change="handleItemChange"></el-cascader>
            </div>
          </a>

          <div class="hotline">
            <img src="../../assets/icon/phone.png" alt="">
            <span>全国服务热线：400-1500-980（周一至周日 08：00~20:00）</span>
          </div>


        </div>
        <div class="top_right_nav flex align-center flex-end">
          <span v-show="isLogin * 1 === 0" class="flex align-center">
            您好 , 请
            <span @click="login" class="color168" style="margin-left:5px;color:#d82229;">登录</span>
          </span>

          <span v-show="isLogin * 1 === 1" class="flex align-center">

            <div>
             您好,{{user.userName | phoneFmt}}
            </div>

            <!-- <div class="loginOut">
                <span>
                  <img src="../../assets/image/touxiang2.png" alt="">
                </span>
                <span @click="logout" style="color:#ccc;">退出</span>
            </div> -->

          </span>
          <span @click="logout" v-if="isLogin * 1 === 1">退出</span>
          <span @click="register" v-if="isLogin * 1 === 0">注册</span>
          <span @click="order">我的订单</span>
          <span @click="helpCenter">帮助中心</span>
          <!-- <span @click="order">帮助中心</span> -->
<!--          <a @click="goMerchant" href="http://www.kqwad.com/selladmin/#/login" target="_blank">商家中心</a>-->
          <!-- <span class="helpcenter">帮助中心</span> -->
        </div>
      </div>
    </div>
    <!-- <img src="../../assets/image/Nav.jpg" alt style="width:100%;"> -->
    <div class="search_content-wrap" :class="{'navBarNone': navBarFixedNone,'navBarWrap' : navBarFixed}">

      <div class="search_content flex align-center" style="cursor:pointer;" >
        <!-- <img src="../../assets/icon/logo.png" alt="" @click="$router.push('/')"> -->

        <img src="../../assets/icon/ad.png" alt="" @click="$router.push('/')">
        <img src="../../assets/icon/shuxian1.png" alt="" style="margin:0 20px">
        <img src="../../assets/icon/xiehui.png" alt="">
        <!-- <img src="../../assets/image/logo.png" > -->
        <div class="search flex-y flex-1">
          <div class="search_input flex">

            <!-- <input type="text" placeholder="请输入关键字" v-model="keyword"> -->
            <el-autocomplete
              v-model="state"
              :fetch-suggestions="querySearchAsync"
              placeholder="搜索 产品/店铺 下单即送等额礼品"
              @select="handleSelect"
              @input="inputValChange"
              @keyup.enter.native="queryAdminRole"
              popper-class="aaa"
            ></el-autocomplete>



            <div
              class="btn_search flex align-center flex-center"
              @click="search(state)"
              style="background：#333 ！important"
            >搜索</div>
          </div>
          <div class="keywords flex align-center">
            <span @click="search('名片', 1)">名片</span>
            <span @click="search('展架', 1)">展架</span>
            <span @click="search('门头', 1)">门头</span>
            <span @click="search('发光字', 1)">发光字</span>
            <span @click="search('广告', 1)">广告</span>
          </div>
        </div>
<!--        <div class="btn_send_need flex align-center flex-center"  @click="publish">-->
<!--          &lt;!&ndash; <img src="../../assets/image/fabu.png" alt> &ndash;&gt;-->
<!--          <span>发布需求</span>-->
<!--        </div>-->
      </div>
    </div>

    <!-- 获取位置需要 -->
    <div id="allmap" style="height:240px;display: none;"></div>
  </div>
</template>

<script>
  var _hmt = _hmt || [];
  (function() {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?f675619aa9ab1259d20920f8432add28";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
  })();
import { mapGetters, mapMutations } from 'vuex'
import { location } from '../../assets/js/LocationUtil'

export default {
  data() {
    return {
      state1:"",
      isNavigation:true, //是否展示最顶部图片
      navBarFixedNone:false, //控制导航隐藏在上方的类名
      navBarFixed:false, //
      cityObj:{},
      cityArr:[], //地区返回信息保存的数组
      isCity:false, //是否展示三级地市区选择
      id:"", //获取城市的id
      options: [], //保存省市区的数组
      restaurants: [], //过滤后的匹配列表数据
        state: '',
        timeout:  null,
      keyword: ''
    }
  },
  computed: {
    ...mapGetters(['user', 'isLogin', 'keywords'])
  },
  mounted() {
           // 百度地图API功能
        var map = new BMap.Map("allmap");
              var point = new BMap.Point(116.331398,39.897445);
        map.centerAndZoom(point,12);
        var geolocation = new BMap.Geolocation();
        geolocation.getCurrentPosition(function(r){
          if(this.getStatus() === BMAP_STATUS_SUCCESS){
            var mk = new BMap.Marker(r.point);
            map.addOverlay(mk);
            map.panTo(r.point);
                  // console.log('您的位置：'+r.point.lng+','+r.point.lat);
                  sessionStorage.setItem('lat',  r.point.lat );
                  sessionStorage.setItem('lng', r.point.lng);
          }
          else {
            alert('failed'+this.getStatus());
          }
        },{enableHighAccuracy: true})

    // this.getLocation();
    this.inputValChange(); //调用搜索框
    this.getCity();
    // window.addEventListener('scroll', this.handleScroll, true);  // 监听（绑定）滚轮滚动事件
   // 事件监听滚动条
      window.addEventListener('scroll', this.watchScroll);
      // let oTarget=document.getElementsByClassName("el-scrollbar")[0]; //搜索列表父元素
      // let oList=document.getElementsByClassName("el-autocomplete-suggestion__list")[0]; //搜索下拉列表元素
      // let oShop=document.createElement('div'); //创建元素
      // oShop.innerHTML="店铺";
      // oList.classList.add('mystyle');
      // console.log(oTarget,oList,oShop)
      // oTarget.insertBefore(oShop,oList);
      // oList.appendChild(oShop);
    // console.log(oList)
  },
  updated() {
    // this.getLocation();
    // if(localStorage.getItem("city")!=null){
    //   var str=localStorage.getItem("city");
    //   str = str.replace(/(^\"*)|(\"*$)/g, "");
    //   document.getElementById('address').innerHTML = str;
    // }


    //如果用户选择了城市
    if(localStorage.getItem("city")!=null){
      var str=localStorage.getItem("city");
      str = str.replace(/(^\"*)|(\"*$)/g, "");
      document.getElementById('address').innerHTML = str;
    }

    //首次加载默认展示南京
    if(localStorage.getItem("city")==null){
      localStorage.setItem("city",JSON.stringify("南京"));
      document.getElementById('address').innerHTML = "南京";
    }
  },
  created() {

  },

   destroyed: function () {
    //  alert(333)
    // window.removeEventListener('scroll', this.watchScroll);   //  离开页面清除（移除）滚轮滚动事件
  },
  methods: {
    // watchScroll () {
    //     var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
    //     //  当滚动超过 50 时，实现吸顶效果
    //     if (scrollTop > 150) {
    //       this.navBarFixedNone=true;
    //     }else{
    //       this.navBarFixedNone=false;
    //     }
    //     if (scrollTop > 260) {
    //       this.navBarFixed = true
    //     } else {
    //       this.navBarFixed = false
    //     }
    //  },
  // aaa(){
    // this.getLocation();
  //   console.log(55)
  // },
  logout() {
      this.$confirm('确认退出当前用户登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(key => {
          console.log(44)
          localStorage.removeItem ('TOKEN')
          localStorage.removeItem ('lat')
          localStorage.removeItem ('lng')
          this.saveUser({})
        })
        .catch(() => {})
    },
      goMerchant(){ //去商家后台
        // console.log(this.isLogin)
        // if(!this.isLogin){ //未登录
        //   this.$router.push("/login");
        // }
        // if(this.isLogin){ //登录状态
        //   this.$router.push("/login");
        // }
      },


      handleItemChange(e) {
        this.isCity=false;
          this.id=e[e.length-1];
          setTimeout(()=>{
            this.$http.get('area/getLonLatByCityName', { params: { id: this.id} }).then(res => {
                this.cityObj=res.data.data;
                sessionStorage.setItem("lat1",res.data.data.latitude);
                sessionStorage.setItem("lng1",res.data.data.longitud);
                localStorage.setItem("city",JSON.stringify(res.data.data.name));
                sessionStorage.setItem("acode",JSON.stringify(res.data.data.adcode));

                document.getElementById('address').innerHTML = res.data.data.name;
                this.$router.go(0)
            })
          },1000)
           setTimeout(()=>{
              localStorage.removeItem("city");
           },600000)
      },
      getCity(){ //获取城市地址
        this.$http.get('/area/getTree').then(res => {
         this.options=res.data.data[0].children;
        })
      },



    queryAdminRole(){ //点击搜索到的列表触发的事件
        if(this.state==""){
           this.$message({
            message: '输入内容不能为空！',
            type: 'warning'
          });
          return;
        }
        this.$router.push({
          path:"/searchProduct",
          query:{
            val:this.state
          }
        });
        let oSeclect=document.getElementsByClassName ("el-autocomplete-suggestion")[0];
        oSeclect.style.display="none";
        // this.state="";
    },
    inputValChange(){ //搜索框输入事件

    let self=this;
      if(self.state==""){
        this.restaurants=[];
          return;
        }
        // debugger
      this.$http.get('/solr/suggest/list', { params: { keyWord: this.state} }).then(res => {

          let arr =res.data.data;
          let arr1=[]
          for(let i=0;i<arr.length;i++){
             let obj={
               id:i,
               value:arr[i].NAME,
               cateId:arr[i].cateId,
               state: arr[i].state,
             }
            arr1.push(obj)
          }
          // debugger
          this.state1=this.state;
          let obj1={id:-1,value: "搜索\""+this.state+"\"相关店铺"}
          arr1.unshift(obj1)
        this.restaurants= arr1;
        // console.log( this.restaurants)

      })
    },

    // loadAll() {
    //     return [
    //       { "value": "三全鲜食（北新泾店）", "address": "长宁区新渔路144号" },
    //       { "value": "Hot honey 首尔炸鸡（仙霞路）", "address": "上海市长宁区淞虹路661号" },
    //       { "value": "新旺角茶餐厅", "address": "上海市普陀区真北路988号创邑金沙谷6号楼113" }
    //     ];
    //   },

      querySearchAsync(queryString, cb) { //组件自带的搜索方法
        var restaurants = this.restaurants;
        var results = restaurants;
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          cb(results);
        }, 0 * Math.random());
      },
      createStateFilter(queryString) {
        // return (state) => {
        //   return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        // };
      },
      handleSelect(item) { //精准搜索
      // if (item) {

      // }
      if(item.id==-1){
          this.$router.push({
          path:"/searchProduct",
          query:{
            val:this.state1,
            cateId:item.cateId,
            cateState:item.state,
            shopFlag:1 //点击店铺
          }
        });
        this.state="";
      }
       else{
          this.$router.push({
          path:"/searchProduct",
          query:{
            val:this.state1,
            cateId:item.cateId,
            cateState:item.state
          }

        });
        this.state="";
       }
      },

    ...mapMutations({ saveUser: 'USER' }),
    login() {
      this.$router.push({ path: '/login' })
    },
    order() {
      this.$router.push({ path: '/purchaseOrder' })
    },
    helpCenter() {
      this.$router.push({
        name: 'helpCenter',
        params:{
          id:7
        }
         })
    },
    register() {
      this.$router.push({ path: '/register' })
    },
    publish() {
      this.$router.push({ path: '/requirement' })
    },
    basic() {
      this.$router.push({ path: '/personOrder' })
    },
    search(state) { //精准搜索
        // console.log(444)
        // this.state="";
        if(state=="" || state.indexOf(" ")!=-1){
          this.$message({
          message: '输入内容不能为空！',
          type: 'warning'
        });
        return;
        }
          this.$router.push({
          path:"/searchProduct",
          query:{
            val:state
          }
        })
    },
    getLocation() {
    //  console.log(localStorage.getItem("city"),33)
      if(localStorage.getItem("city")==null){
      let _that = this
      let geolocation = location.initMap('map-container') //定位
      AMap.event.addListener(geolocation, 'complete', result => {
        // console.log(result,3333)
        localStorage.setItem('adcode', JSON.stringify(result.addressComponent.adcode) );
        _that.lat = result.position.lat
        _that.lng = result.position.lng
        _that.province = result.addressComponent.province
        _that.city = result.addressComponent.city
        _that.district = result.addressComponent.district
        let city = result.addressComponent.city
        document.getElementById('address').innerHTML = city
        localStorage.setItem('city',  JSON.stringify(city) );
          localStorage.setItem('lat',  _that.lat );
          localStorage.setItem('lng', _that.lng);
      })
          // localStorage.setItem('lat',  _that.lat );
          // localStorage.setItem('lng', _that.lng);
    }
    }
  },
  watch: {
    keywords (val) {
      this.keyword = val;
    },
     $route(to){

      if (this.$route.path=="/") {
        this.isNavigation=true;
      }

      else{
        this.isNavigation=false;
      }
    }
  },
}
</script>

<style lang="scss" scope="">
@import '../../assets/scss/common.scss';

$url: '../../assets/image/location.png';
.active1{
  // height: 170px !important;
}


.header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 2000;
  background: #fff;
  .top_nav_wrap {
    background: #f7f7f7;
    width: 100%;
    .top_nav {
      max-width: 1200px;
      width: 100%;
      height: 34px;
      margin: 0 auto;
      background: #f7f7f7;
      .city {
        flex: 1;
        text-align: left;
        font-size: 12px;
        color: #333;
        line-height: 34px;
        display: flex;
        align-items: center;
        span,a{
          flex-shrink: 0;
        }
        img{
          display: block;
          margin-right: 10px;
        }
        a {
          color: #333;
          cursor: pointer;
          font-size: 12px;
        }
        .address {
          margin-right: 10px;
          font-size: 12px;
        }

      }
      .hotline{
        margin-left: 36px;
        display: flex;
        align-items:center;
        img{
          display: block;
          margin-right: 10px;
        }
      }
      .top_right_nav {
        flex: 1;
        span {
          margin-right: 20px;
          cursor: pointer;
          font-size: 12px;
          color: #333;
          flex-shrink: 0;
        }
        :last-child{
          margin-right: 0;
        }
        .helpcenter {
          margin-right: 18px;
        }
        .color168 {
          margin-right: 0 !important;
        }
        a{
            text-decoration: none;
            font-size: 12px;
            color: #333;
        }
      }
      + img {
        width: 100%;
      }
    }
    .el-cascader{
      width: 175px;
      height: 30px;
      position:absolute;
    }
  }
  .search_content {
    height: 100px;
    margin: 0 auto;
    width: 100%;
    width: 1200px;
    background: #fff;
    margin: 0 auto;
    .search {
      margin-right: 40px;
      margin-left: 119px;
      .search_input {
        flex: 1;
        height: 40px;
        input {
          flex: 1;
          height: 34px;
          -webkit-tap-highlight-color: none;
          outline: none;
          border: none;
          padding: 0 20px;
          font-size: 14px;
          height: 100%;
          box-sizing: border-box;
          border: solid 2px #d82229;
        }
        .btn_search {
          height: 100%;
          background: #d82229;
          width: 96px;
          text-align: center;
          color: #fff;
          cursor: pointer;
          font-weight: 500;
        }

      }
      .keywords {
        font-size: 12px;
        color: #999999;
        margin-top: 8px;
        span {
          padding: 0 10px 0 0;
          position: relative;
          margin-right: 10px;
          cursor: pointer;
          &::after {
            position: absolute;
            content: '/';
            font-weight: 500;
            right: 0;
            top: 2px;
          }
        }
      }
    }
    .btn_send_need {
      width: 120px;
      height: 40px;
      background-color: #333;
      color: #fff;
      font-weight: 500;
      margin-bottom: 24px;
      cursor: pointer;
      // margin-right: 20px;
      flex-shrink: 0;
    }
  }
}

// 以下是新版搜索框追加样式
.search {
  /deep/ .el-input__inner{
      border: 2px solid #d82229;
      border-radius: 0;
  }
   .el-input{
    width: 351px;
    height: 40px;
  }
}

/deep/ .city{
  position: relative;
  .cityAdress{
      position:absolute;
      top: 35px;
      left:0;
  }
  .el-input__inner{
      border-radius: 0;
      height: 35px;
  }
  .el-input{
    width:181px;
  }
  .el-cascader{
    line-height: 35px;
  }

}

.navBarNone{//悬浮导航样式
    top: -150px;
    -moz-transition-property: top;
    -moz-transition-duration: 1s;
    -webkit-transition-property: top;
    -webkit-transition-duration: 1s;
    -o-transition-property: top;
    -o-transition-duration: 1s;
    transition-property: top;
    transition-duration: 1s;
  }
.navBarWrap {
    position:fixed;
    height:78px;
    top:0;
    z-index:999;
    left: 0;
    right: 0;
    width: 100%;
  background: #fff;
  border-bottom: 2px solid #d82229;


    .search_content{
      height: 76px;
    }
  }

 .el-autocomplete-suggestion__list{
  // background: red !important;
  :first-child{
    color: #d82229;
  }
}
.top_nav_wrap{

    .el-message-box__btns button:nth-child(2){
    width: 56px;
    }
    .el-input{
      width: 175px;
        height: 40px;
    }
    .el-input__inner{
      width: 175px;
      border-radius: 0;
    }
    .top_nav_wrap .el-input{
      width: 175px;
    }
    .el-autocomplete-suggestion__list{
      display: none;
    }

}
.el-cascader-menu{
  height: 360px !important;
}

.el-message-box__btns{
  :nth-child(2){
    width: 56px;
  }
  .el-button--primary{
    background: #d82229;
    border: none;
  }

}
.header .search_content .search .search_input input{
      border-radius: 0;
      height: 40px;
  }

</style>


