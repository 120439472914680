<template>
    <div id="footer">
            <FooterProtection></FooterProtection>
        <div class="first">

            <div class="content flex-center align-center flex">
                <div class="info flex-center align-center flex">
                    <div v-for="(item,index) in headerData" :key="index">
                         <div class="big">{{item.name}}</div>
                         <div >
                                <!-- 循环二级菜单数据并使用.stop阻止冒泡 -->
                                <div class="small" v-for="(a,index) in item.list" :key="index" @click="gohelpCenter(a.id)">{{a.name}}</div>
                            </div>
                    </div>

                    <!-- <div>
                        <div class="big">新手指南</div>
                        <div>注册登录</div>
                        <div>下单流程</div>
                        <div @click="publish">发布需求</div>
                    </div>
                    <div>
                        <div class="big">支付方式</div>
                        <div>支付宝支付</div>
                        <div>微信支付</div>
                        <div>银行转账</div>
                        <div>线下支付</div>
                    </div>
                    <div>
                        <div class="big">配送方式</div>
                        <div>配送方式</div>
                        <div>配送范围及运费</div>
                        <div>验货与签收</div>
                    </div>
                    <div>
                        <div class="big">退款售后</div>
                        <div>退款说明</div>
                        <div>返修/退换货</div>
                        <div>售后政策</div>
                        <div>售后服务</div>
                        <div>价格保护</div>
                    </div> -->
                </div>
                <div class="time">
                    <p>400-1500-980</p>
                    <p>周一至周日8:00-18:00</p>
                </div>
                <div class="ewm">
                    <div></div>
                    <p>微信扫码快速下单</p>
                </div>
            </div>
            <div class="more">
                <div class="flex-center align-center flex">
                    <a>关于我们</a>
                    <a>
                        <router-link to="join">商家入驻</router-link>
                    </a>
                    <a>协议规则</a>
                    <a>孔雀诚聘</a>
                    <a>联系我们</a>
                </div>
                <div class="flex-center friendLink">
                    <a>友情链接:</a>
                    <div v-for="(item,i) in LinkList" :key="i">
                        <a :href="item.link" target="_blank" title="item.siteName">{{item.siteName}}</a>
                    </div>
                    <!-- <a href="http://www.china-caa.org/">中国广告协会</a>
                    <a href="http://www.jinkun88.com/" title="环氧胶" target="_blank">环氧胶</a>
                    <a href="http://www.gg0371.com" title="公交车广告">公交车广告</a>
                    <a href="http://www.qingfeiyang.cn/" title="青岛广告公司">青岛广告公司</a>
                    <a href="http://www.huanyuguanggao.com/" target="_blank" title="青岛广告">青岛广告</a>
                    <a href="http://www.xjyled.cn/" tltle="LED显示屏公司" target="_blank">LED显示屏公司</a>
                    <a href="http://www.zzsyi.com/" target="_blank" title="车身广告">车身广告</a>
                    <a href="http://hulianwang.jiameng.com/" target="_blank" title="互联网加盟">互联网加盟</a>
                    <a href="http://www.jysdcm.com" title="山西擎天柱广告">山西擎天柱广告</a>
                    <a href="http://alpha.zx58.cn/" target="_blank" title="安发公司">安发公司</a>
                    <a href="http://www.danyuan.net" target="_blank" title="沈阳空间展示设计">沈阳空间展示设计</a>
                    <a href="http://www.feilik.com.cn" target="_blank" title="郑州宣传片制作">郑州宣传片制作</a>
                    <a href="http://www.huarsheng.cn" target="_blank" title="广告反光材料">广告反光材料</a>
                    <a href="http://www.tvcgg.com/" title="广告片" target="_blank">广告片</a>
                    <a href="http://www.wms100.com/" title="wms" target="_blank">wms</a>
                    <a href="http://www.withubmba.org/" title="NPDP" target="_blank">NPDP</a>
                    <a href="http://www.fud.cn/" title="品牌策划方案" target="_blank">品牌策划方案</a>
                    <a href="http://rongduban.com/" title="成都公司注册" target="_blank">成都公司注册</a>
                    <a href="http://www.oboo.cn/" title="液晶广告机" target="_blank">液晶广告机</a>
                    <a href="http://www.021starspr.com/" title="上海活动策划" target="_blank">上海活动策划</a>
                    <a href="http://www.yichuangdesign.com/" title="杭州活动策划公司" target="_blank">杭州活动策划公司</a>
                    <a href="http://www.sunny688.com/" title="普洱茶" target="_blank">普洱茶</a>
                    <a href="http://www.fsalifz.com/" title="服装定制" target="_blank">服装定制</a>
                    <a href="http://www.royalpc.com.cn/" title="条码打印机" target="_blank">条码打印机</a>
                    <a href="http://www.jswuansy.com/" title="全新亚克力板" target="_blank">全新亚克力板</a>
                    <a href="http://www.chinasjha.com/" title="广告片制作" target="_blank">广告片制作</a> -->
                </div>
                <div class="flex-center align-center flex ad">
                    <a>Copyright 2017-2027 kqwad.com 版权所有</a>
                    <a href="https://beian.miit.gov.cn/" class="ba">苏ICP备17053874号-1</a>
                    <a>南京广而告知信息技术有限公司</a>
                </div>
                <div class="flex-center align-center flex ad">
                    <a>免责声明： 平台所展示的信息由企业/个人自行提供或来自互联网，如果内容不真实或侵犯您的权益，请联系平台客服，我们会在第一时间核实处理！</a>
                </div>
                <div class="flex-center align-center flex">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FooterProtection from "@/components/common/footerProtection.vue";
    export default {
        name: 'index',
        components:{FooterProtection},
        data() {
            return {
                aa:"www",
                LinkList:[],
                headerData: []
            };
        },
        created() {
            this.getLink();
            this.getTree();
        },
        methods: {
            gohelpCenter(id){
                this.$router.push({
                    name:"helpCenter",
                    params:{
                        id:id
                    }
                })
            },
            getTree(){
            this.$http.get("/help/cate").then(res=>{
                this.headerData=res.data.data;
                // this.headerData.forEach((item,i)=>{
                //     this.$set(item, "show", false);
                // })
                // this.defauldId=this.headerData[0].list[0].id;
                // this.defaultDetail()
            })

        },
            publish() {
            this.$router.push({ path: '/requirement' })
            },
            getLink(){
                this.$http.post('friendship/link/list').then(res=>{
                    this.LinkList=res.data.data;
                    // console.log(res,555)
                })
            }
        },
    };
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/common.scss';
    a{
        text-decoration: none;
        color: #fff;
        &:hover {
            text-decoration: underline;
        }
    }
    #footer {
        width: 100%;
        background-color: #f5f5f5;
        position: relative;
        bottom: 0;
        .first {
            width: 100%;
            height: 100%;
            margin: 0 auto;
             background: #333;
            border-top:2px solid #d82229;
            .content {
                width: 1200px;
                margin: 0 auto;
                height: 260px;
                padding: 20px 0;
                border-bottom: 1px solid #cccccc;
                .logo,
                .ewm {
                    width: 20%;
                    height: 100%;
                }
                .info {
                    width: 65%;
                    height: 100%;
                    > div {
                        width: 25%;
                        height: 100%;
                        color: #fff;
                        // text-align: center;
                        padding-left: 60px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        // >:last-child{
                        //     color: red;
                        //      font-size: 12px;
                        //     margin-bottom: 15px;
                        // }
                        // > div {
                        //     font-size: 12px;
                        //     margin-bottom: 15px;
                        //     color: #fff;
                        //     // &:not(:first-child) {
                        //     //     cursor: pointer;
                        //     //     &:hover {
                        //     //         text-decoration: underline;
                        //     //     }
                        //     // }
                        // }
                        >div{
                            margin-top: 20px;
                        }
                        .small{
                            font-size: 12px;
                            margin-bottom: 15px;
                            color: #fff;
                            cursor: pointer;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                        .big {
                            font-size: 18px;
                            margin-top: 20px;
                            color: #fff;
                        }
                    }
                }
                .logo {
                    > div {
                        &:first-child {
                            width: 100%;
                            height: 60px;
                            padding: 20px 0;
                            // background: url('../../assets/image/logogray.png') no-repeat center;
                            background-size: 70%;
                        }
                        &:last-child {
                            color: #fff;
                            text-align: center;
                            > p {
                                &:first-child {
                                    font-size: 34px;
                                }
                                &:last-child {
                                    text-align: center;
                                    padding: 0 50px;
                                }
                            }
                        }
                    }
                }
                .time {
                    width: 15%;
                    text-align: center;
                    font-size: 16px;
                    color: #fff;
                    > p {
                        &:first-child {
                            font-size: 26px;
                        }
                    }
                }
                .ewm {
                    > div {
                        width: 120px;
                        height: 120px;
                        margin: 20px auto;
                        background: url('../../assets/image/code.jpg') no-repeat center;
                        -webkit-background-size: contain;
                    }
                    > p {
                        text-align: center;
                        color: #fff;
                        font-size: 12px;
                    }
                }
            }
            .more {
                width: 1200px;
                margin: 0 auto;
                margin-top: 25px;
                > div {
                    &:first-child {
                        color: #fff;
                        text-align: center;
                        a {
                            width: 80px;
                            height: 15px;
                            text-align: center;
                            line-height: 15px;
                            margin: 15px 0;
                            font-size: 12px;
                            color: #fff;
                            cursor: pointer;
                            & {
                                a {
                                    text-decoration: none;
                                }
                            }
                            &:hover {
                                text-decoration: underline;
                            }
                            &:not(:last-child) {
                                border-right: 1px solid #f1f1f1;
                            }
                        }
                    }
                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4) {
                        color: #fff;
                        // text-align: center;
                        margin: 10px 0;
                        a {
                            font-size: 12px;
                            margin-right: 30px;
                            &:not(:first-child) {
                                cursor: pointer;
                                color: #fff;
                                text-decoration: none;
                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                    &:last-child {
                        > div {
                            width: 80px;
                            height: 30px;
                            background: url('../../assets/image/store.jpg') no-repeat center;
                            background-size: contain;
                            margin-right: 30px;
                            margin-bottom: 30px;
                        }
                    }
                }


                .friendLink{
                    width: 1200px;
                    margin:0 auto;
                    display: flex;
                    flex-wrap: wrap;
                    line-height: 22px;
                }
                .ad{
                    a{
                        text-decoration: none;
                        cursor: default !important;
                        &:hover {
                            text-decoration: none !important;
                        }
                    }
                    .ba{
                        &:hover {
                            text-decoration: none !important;
                            cursor: pointer !important;
                        }
                    }
                }
            }
        }
    }
</style>
