<template>
         <div class="promise flex">
                 <div class="flex-center flex align-center" @click="showdzlz()">
                     <div class="smal01"></div>
                     <div>
                         <h2>电子亮照</h2>
                         <h3>营业执照</h3>
                     </div>
                 </div>
                <div class="flex-center flex align-center">
                    <div class="small1"></div>
                    <div>
                        <h2>商家保证</h2>
                        <h3>品质保证</h3>
                    </div>
                </div>
                <div class="flex-center flex align-center">
                    <div class="small2"></div>
                    <div>
                        <h2>多商比价</h2>
                        <h3>更多选择</h3>
                    </div>
                </div>
                <div class="flex-center flex align-center">
                    <div class="small3"></div>
                    <div>
                        <h2>支付担保</h2>
                        <h3>交易安全</h3>
                    </div>
                </div>
                <div class="flex-center flex align-center">
                    <div class="small4"></div>
                    <div>
                        <h2>买家评价</h2>
                        <h3>信用参考</h3>
                    </div>
                </div>
            </div>
</template>

<script>
export default {
        name:"footerProtection",
    data() {
        return {

        }
    },
    methods:{
         showdzlz() {
            window.location.href = "https://zzlz.gsxt.gov.cn/businessCheck/verifKey.do?showType=p&serial=91320104MA1PCFEL9W-SAIC_SHOW_10000091320104MA1PCFEL9W1606878391513&signData=MEQCIB1zBuKEColuun9mNskIB4n2fYrnL+3PRCxrlkuci0FOAiBhACtWRrcukqZEJP/OAvX7m+3lWQYfHgsjHms4hVev8w==";
        }
    }
}
</script>

<style lang="scss" scope="">
    .promise {
                justify-content: space-around;
                width: 1200px;
                margin: 0 auto;
                background: #f5f5f5;
                > div {
                    width: 25%;
                    height: 70px;
                    margin: 20px 0 20px;
                    .smal01 {
                        background: url("https://www.kqwad.com/dist/static/image/pcsite_lz4.png") no-repeat center;
                    }
                    .small1 {
                        background: url("https://www.kqwad.com/dist/static/image/small1.png") no-repeat center
                    }
                    .small2 {
                        background: url("https://www.kqwad.com/dist/static/image/small2.png") no-repeat center
                    }
                    .small3 {
                        background: url("https://www.kqwad.com/dist/static/image/small3.png") no-repeat center
                    }
                    .small4 {
                        background: url("https://www.kqwad.com/dist/static/image/small4.png") no-repeat center
                    }
                    > div {
                        &:first-child {
                            width: 60px;
                            height: 100%;
                            background-size: contain;
                            vertical-align: middle;
                        }
                        &:last-child {
                            height: 100%;
                            margin-left: 28px;
                            text-align: left;

                            h2,
                            h3 {
                                margin: 0;
                                padding: 0;
                            }
                            h2 {
                                font-size: 22px;
                                padding-bottom: 10px;
                                color: #333;
                            }
                            h3 {
                                font-size: 18px;
                                text-align: center;
                                color: #666;
                            }

                        }
                    }
                }
            }
</style>
